import { useNavigate } from 'react-router-dom';

import { HeroSection, Page } from '@components';
import { BackButton } from '@components';

import slapVersusImg from '@media/slap-versus.png';
import slapVersusCardImg from '@media/slap-versus-card.png';

import s from './SlapVersusPage.module.scss';

export const SlapVersusPage = () => {
  const navigate = useNavigate();

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <HeroSection
        title="Slap Versus"
        desc=" Face off against random opponents in a fierce slapping fight"
        coming="Coming soon..."
        img={slapVersusImg}
        imgMargin={''}
        imgHeight={169}
        imgWidth={'100%'}
      />
      <img src={slapVersusCardImg} className={s.imgCard} alt="quests" />
    </Page>
  );
};
