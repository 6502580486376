import React from 'react';
import clsx from 'clsx';

import s from './ProgressBar.module.scss';

interface ProgressBarProps {
  className?: string;
  variant?: 'rounded' | 'rectangular' | 'vertical';
  currentNumber?: number;
  maxNumber?: number;
  minNumber?: number;
  fillBarColor: string;
  barBgColor: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  variant = 'rounded',
  currentNumber = 0,
  maxNumber,
  minNumber,
  fillBarColor,
  barBgColor,
}) => {
  const percent =
    maxNumber !== undefined ? (currentNumber / maxNumber) * 100 : 100;

  const classNames = clsx(s.root, className, s[variant]);

  const customStyle = {
    '--fill-bar-color': fillBarColor,
    '--bar-bg-color': barBgColor,
  } as React.CSSProperties;

  return (
    <div className={classNames} style={customStyle}>
      {variant === 'rectangular' && (
        <span className={clsx(s.digits, s.orange)}>{currentNumber}</span>
      )}
      <div className={s.bar}>
        <div
          className={s.barFill}
          style={
            variant === 'vertical'
              ? { height: `${percent}%` }
              : { width: `${percent}%` }
          }
        ></div>
      </div>
      {variant === 'vertical' && (
        <div className={s.round}>
          <div className={s.roundFill}></div>
        </div>
      )}
      {variant === 'rectangular' && (
        <span className={s.digits}>{maxNumber}</span>
      )}
    </div>
  );
};
