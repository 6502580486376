import React from 'react';
import clsx from 'clsx';

import { Button } from '@ui-kit';

import { ReactComponent as InfoIcon } from '@icons/info.svg';

import s from './HeroSection.module.scss';

interface HeroSectionProps {
  className?: string;
  title?: string;
  desc?: string;
  coming?: string;
  img?: string;
  imgWidth?: number | string;
  imgHeight?: number | string;
  imgMargin?: string;
  btnLable?: string;
  onClick?: () => void;
}

export const HeroSection: React.FC<HeroSectionProps> = ({
  className,
  title,
  desc,
  coming,
  img,
  imgWidth,
  imgHeight,
  imgMargin,
  btnLable,
  onClick,
}) => {
  const customStyle = {
    '--img-margin': imgMargin,
  } as React.CSSProperties;

  return (
    <div className={clsx(s.root, className)} style={customStyle}>
      <div className={s.heroImgWrap}></div>

      <div className={s.contentWrap}>
        {title && <h4 className={s.title}>{title}</h4>}

        {desc && <p className={s.desc}>{desc}</p>}
        {coming && <p className={s.coming}>{coming}</p>}
        {btnLable && (
          <Button
            className={s.link}
            btnType="link"
            variant="unfilled"
            size="large"
            rightIcon={<InfoIcon width={24} height={24} />}
            gap="4px"
            onClick={onClick}
          >
            {btnLable}
          </Button>
        )}
      </div>
      {img && (
        <img
          src={img}
          width={imgWidth}
          height={imgHeight}
          className={s.img}
          alt="quests"
        />
      )}
    </div>
  );
};
